<template>
  <div
    style="
      background-image: url('img/radio/room_bg.png');
      background-size: 100% 100%;
      height: calc(100vh - 120px);
      background-color: #431C68;
      background-repeat: no-repeat;
      overflow-y: scroll;
      padding-bottom: 120px;
    "
  >
  <!-- <back-nav class="title_top" :icon="'img/backw.png'" :textColor="'#fff'" :backColor="'none'" /> -->
    <div
      class="roomNav"
    >
      <div
        class="room-title"
        v-for="(item, index) in ranklist"
        :key="index"
        :style="rankCur == index ? 'background-image:url(' + item.navAcBg + ')' : 'background-image:url(' + item.navBg + ')'"
        @click="rankchange(index, item.list_type)"
      >
      </div>
    </div>
    <div class="ranktype">
      <div
        class="romitem"
        :class="{ romName: ranklist[rankCur].cur == index }"
        v-for="(item, index) in ranklist[rankCur].type"
        :key="index"
        @click="typechange(index, item.sort_date)"
      >
        {{ item.name }}
      </div>
    </div>

    <div v-if="nodata" class="noData">
      <img :src="'img/noData.png'" alt="" />
    </div>
    <div class="list-scroll_" v-if="listItems.length > 0">
      <template>
        <div
          class="item_ display_flex justify-content_flex-justify align-items_center"
          v-for="(item, index) in listItems"
          :key="index"
          :style="index==0? 'background-image:url(' +'img/radio/item_bg_one.png'+ ')' : 'background-image:url(' + 'img/radio/item_bg.png' + ')'"
        >
          <div
            class="display_flex align-items_center"
            @click="checkUser(item.user_id)"
          >
           <div class="item-order_" v-if="index==0" style="background-image: url('img/radio/one.png');"></div>
           <div class="item-order_" v-if="index==1" style="background-image: url('img/radio/two.png');"></div>
           <div class="item-order_" v-if="index==2" style="background-image: url('img/radio/three.png');"></div>
            <div class="item-order_" v-if="index>2">{{ index + 1 }}</div>
            <div class="head_bg" v-if="index==0" style="background-image: url('img/radio/head_bg_one.png');">
              <img class="item-img_" :src="item.head_portrait" alt="" />
            </div>
            <div class="head_bg" v-if="index==1" style="background-image: url('img/radio/head_bg_two.png');">
              <img class="item-img_" :src="item.head_portrait" alt="" />
            </div>
            <div class="head_bg" v-if="index==2" style="background-image: url('img/radio/head_bg_three.png');">
              <img class="item-img_" :src="item.head_portrait" alt="" />
            </div>
            <div class="head_bg" v-if="index>2">
              <img class="item-img_" :src="item.head_portrait" alt="" />
            </div>
            <div class="display_flex align-items_center">
              <template>
                <div class="item-name_">{{ item.nickname }}</div>
              </template>
              <!-- <div
                class="item-level"
                :style="{
                  backgroundImage:
                    'url(' +
                    'img/level/level' +
                    rankCur +
                    '/' +
                    item.user_level +
                    '.png' +
                    ')',
                }"
              ></div> -->
            </div>
          </div>
          <div class="item-right">
            <div class="display_flex align-items_center">
              <span style="color: #FFE7CC; opacity: 0.7" v-if="rankCur == 0"
                >财富值</span
              >
              <span style="color:#FFE7CC; opacity: 0.7" v-if="rankCur == 1"
                >魅力值</span
              >
            </div>
            <template>
              <!-- <p v-if="rankCur == 3">{{ item.value }}</p> -->
              <p style="color: #FFE7CC">
                {{ is_admin ? item.total_charm_value : "****" }}
              </p>
            </template>
          </div>
        </div>
      </template>
      <van-overlay :show="loading">
        <div style="display: flex;justify-content: center;align-items: center;width: 100%;height: 100%;">
          <van-loading/>
          <div style="color: #ffffff;">数据加载中...</div>
        </div>
        
      </van-overlay>
    </div>
  </div>
</template>

<script>
import "@/assets/css/rank.css";
import BackNav from "@/components/backNav.vue";
import {
  roomWealthList,
  roomCharismaList,
  surpriseList,
  luckyList,
} from "@/api/ranking";
export default {
  name: "room",
  components: {
    BackNav,
  },
  data() {
    return {
      rankCur: 0,
      loading: false,
      ranklist: [
        {
          name: "财富榜",
          type: [
            {
              name: "日榜",
              sort_date: "day",
            },
            {
              name: "周榜",
              sort_date: "week",
            },
            {
              name: "月榜",
              sort_date: "month",
            },
            {
              name: "昨日",
              sort_date: "yesterday",
            }
          ],
          cur: 0,
          topbg: "img/radio/01.png",
          navBg:'img/radio/cf_default.png',
          navAcBg:'img/radio/cf_active.png'
        },
        {
          name: "魅力榜",
          type: [
            {
              name: "日榜",
              sort_date: "day",
            },
            {
              name: "周榜",
              sort_date: "week",
            },
            {
              name: "月榜",
              sort_date: "month",
            },
            {
              name: "昨日",
              sort_date: "yesterday",
            }
          ],
          cur: 0,
          topbg: "img/radio/02.png",
          navBg:'img/radio/ml_default.png',
          navAcBg:'img/radio/ml_active.png'
        }
      ],
      sort_date: "day",
      topThreeItems: [],
      listItems: [],
      nodata: false,
      is_admin: false,
    };
  },
  created() {
    let access_token = this.$route.query.access_token;
    localStorage.setItem("access_token", access_token);
    this.getData();
  },
  methods: {
    //榜单切换
    rankchange(e, type) {
      this.rankCur = e;
      this.sort_date = "day";
      this.ranklist[e].cur = 0;
      this.getData();
    },
    //周榜总榜切换
    typechange(e, type) {
      let rankCur = this.rankCur;
      this.ranklist[rankCur].cur = e;
      this.sort_date = type;
      this.getData();
    },
    getData() {
      let Params = {
        room_id: this.$route.query.room_id,
        date_type: this.sort_date,
      };
      this.topThreeItems = []
      this.listItems = []
      this.loading = true;
      if (this.rankCur == 0) {
        roomWealthList(Params)
          .then(
            (response) => {
              this.loading = false;    
              this.listItems = response.data.list 
              this.is_admin = response.data.is_admin;
              if (response.data.list.length == 0) {
                this.nodata = true;
              } else {
                this.nodata = false;
              }
            },
            (error) => {
              this.$toast(error);
            }
          )
          .catch((error) => {
            this.$toast(error);
          });
      }
      if (this.rankCur == 1) {
        roomCharismaList(Params)
          .then(
            (response) => {
              this.loading = false;
              this.listItems =  response.data.list
              this.is_admin = response.data.is_admin;
              if (response.data.list.length == 0) {
                this.nodata = true;
              } else {
                this.nodata = false;
              }
            },
            (error) => {
              this.$toast(error);
            }
          )
          .catch((error) => {
            this.$toast(error);
          });
      }
      if (this.rankCur == 2) {
        surpriseList(Params)
          .then(
            (response) => {
              this.loading = false;
              this.loading = false;
              this.listItems =  response.data.list
              this.is_admin = response.data.is_admin;
              if (response.data.list.length == 0) {
                this.nodata = true;
              } else {
                this.nodata = false;
              }
            },
            (error) => {
              this.$toast(error);
            }
          )
          .catch((error) => {
            this.$toast(error);
          });
      }
      if (this.rankCur == 3) {
        luckyList(Params)
          .then(
            (response) => {
              this.loading = false;
               this.listItems = response.data.list
              this.is_admin = response.data.is_admin;
              if (response.data.list.length == 0) {
                this.nodata = true;
              } else {
                this.nodata = false;
              }
            },
            (error) => {
              this.$toast(error);
            }
          )
          .catch((error) => {
            this.$toast(error);
          });
      }
    },
    closePage() {
      let params = "调用";
      this.$bridge.callhandler("closePage", params, (data) => {
        // 处理返回数据
      });
    },
    checkUser(id) {
      let param = id.toString();
      this.$bridge.callhandler("checkUser", param, (data) => {
        // 处理返回数据
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->